<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Terrassenüberdachung / Lewens / Murano Vetro <br />
    </h2>
    <div class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Murano Vetro - Glasdach
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full lg:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Perfekter Wetterschutz für große Terrassen
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Das Glasdach Murano Vetro bietet Wetterschutz zu jeder Jahreszeit
            und verlängert so die Terrassensaison. Die elegante Konstruktion
            lässt sich ohne großen Aufwand auf fast jeder Terrasse montieren und
            eignet sich mit groß angelegten Maximalmaßen auch für weite Flächen.
            Ausgestattet mit vorn und seitlich bündigen oder eingerückten
            Pfosten stehen diverse Typen und Bauformen zur Wahl.<br /><br />
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Typen">
            <div class="text-lg">
              <span class="font-bold"
                >Erhältlich in verschiedene Produktvarianten
              </span>
              <br />
              <div class="flex flex-col sm:flex-row">
                <div class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/vetro/lew-murano-vetro-b-typ-ganz-fs-uli.webp"
                      style="min-width: 10rem"
                      alt="glasdach murano vetro typ buendig"
                    />
                  </div>
                  <div class="lg:pl-16">Murano Vetro Typ Bündig (B)</div>
                </div>
                <div class="px-2 flex flex-col w-2/3">
                  <div class="w-1/3">
                    <img
                      src="../assets/img/lewens/vetro/lew-murano-vetro-ue-typ-ganz-uli.webp"
                      style="min-width: 10rem"
                      alt="glasdach murano vetro typ ueberstand"
                    />
                  </div>
                  <div class="lg:pl-16">Murano Vetro Typ Überstand (Ü)</div>
                </div>
              </div>
            </div>
          </tab>
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Maßen</span> <br />
              <span class="font-bold">Breite:</span> <br />
              max. 700 cm - 1-teilig (Einzelfeld)<br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 500 cm <br />
              <span class="font-bold">Verglasung:</span> <br />
              für VSG 8 mm <br />
              für VSG 10 mm <br />
              für VSG 12 mm <br />
              für Doppelstegplatten 16 mm
            </p>
          </tab>
          <tab title="Zertifizierung">
            <div class="text-lg">
              <span class="font-bold">Zertifizierung:</span> <br />
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div>
                    <img
                      src="../assets/madeingermany.webp"
                      alt="made in germany siegel"
                    />
                  </div>
                  <div class="w-full pl-1">
                    Hergestellt und montiert in Deutschland
                  </div>
                </div>
                <div class="flex flex-row">
                  <div><img src="../assets/ce-norm.webp" alt="ce-norm" /></div>
                  <div class="w-full pl-1">
                    Qualität und Technik nach CE-Norm
                  </div>
                </div>
                <div class="flex flex-row">
                  <div>
                    <img src="../assets/tuev.webp" alt="tuev geprueft" />
                  </div>
                  <div class="w-full pl-1">TÜV-geprüfte Sicherheit</div>
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </div>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/lewens/vetro/lew-murano-vetro-b-ganz-haus-uli.webp"),
          alt: "glasdach murano vetro nachts",
        },
        {
          image: require("@/assets/img/lewens/vetro/modelle-web-rd-21.webp"),
          alt: "detailansicht",
        },
        {
          image: require("@/assets/img/lewens/vetro/lew-murano-vetro-ue-detail-uli.webp"),
          alt: "detailansicht vorher ausfahrrichtung",
        },
        {
          image: require("@/assets/img/lewens/vetro/lew-murano-vetro-b-detail-uli.webp"),
          alt: "detailansicht danach ausfahrrichtung",
        },
        {
          image: require("@/assets/img/lewens/vetro/lew-murano-vetro-b-detail-rinne-uli.webp"),
          alt: "detailansicht seitlich",
        },
        {
          image: require("@/assets/img/lewens/vetro/lew-murano-vetro-ue-detail-rinne-uli.webp"),
          alt: "detailansicht",
        },
      ],
    };
  },
};
</script>
